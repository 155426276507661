<template>
    <styled-slideout
        :value="value"
        @input="$emit('input', $event)">
        <div class="pa-5">
            <h2>Inventory Export Request Emails</h2>
            <p class="mb-4">
                Use the example messaging below to obtain approval from {{ dealer.name }}
                and have {{ feedProvider.name }} export inventory to BuyerBridge.
            </p>
            <styled-list>
                <div>
                    <request-approval-message
                        :feed-provider="feedProvider" />
                </div>
                <div>
                    <request-for-export-message
                        :feed-provider="feedProvider" />
                </div>
            </styled-list>
        </div>
    </styled-slideout>
</template>

<script>
import RequestApprovalMessage from '@/components/globals/InventoryRequestMessages/RequestApprovalMessage';
import RequestForExportMessage from '@/components/globals/InventoryRequestMessages/RequestForExportMessage';
import StyledSlideout from '@/components/globals/StyledSlideout';
import StyledList from '@/components/globals/StyledList';

export default {
    name: 'ResendFeedProvider',
    components: {
        RequestApprovalMessage,
        RequestForExportMessage,
        StyledSlideout,
        StyledList,
    },
    props: {
        dealer: {
            type: Object,
            required: true
        },
        feedProvider: {
            type: Object,
            required: true
        },
        value: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        value(newValue, oldValue) {
            // If we're transitioning to open and the form was previously
            // submitted reset it
            if (newValue === true
                && oldValue === false
                && this.complete === true) {
                this.complete = false;
            }
        }
    }
};
</script>