<template>
    <div>
        <new-feed-provider-request-slideout v-model="newProviderDialogOpen" />

        <resend-feed-provider-dialog
            v-if="feedProvider"
            v-model="messagingDialogOpen"
            :feed-provider="feedProvider"
            :dealer="currentDealer" />

        <v-alert
            :value="error"
            class="mb-4">
            {{ error }}
        </v-alert>

        <styled-callout
            v-if="dealerFeedRdi"
            class="layout align-center mb-4">
            <div class="flex shrink mr-3">
                <status-icon
                    :value="feedRdiConfigured ? 'success' : 'warning'"
                    size="25" />
            </div>
            <div class="flex">
                <p>
                    {{ currentDealer.name }}'s feed inventory has been successfully configured with
                    {{ dealerFeedRdi.vehicle_data_source.name }}.  Please note that it cannot be changed during
                    onboarding.
                </p>
                <p v-if="useFeedAutoDetection && dealerFeedRdi.auto_detect_id_string">
                    <strong>Auto-detection is currently activate</strong> so sit back, relax and we'll send
                    you and email when it's processed!
                </p>
                <p v-else-if="!feedRdiConfigured">
                    <strong>You still need to work with {{ dealerFeedRdi.vehicle_data_source.name }}
                        to obtain an ID</strong> so please follow the instructions below to finalize this process.
                </p>
            </div>
        </styled-callout>

        <h2>Configure Feed Provider</h2>
        <p>
            You’ve chosen a play that requires vehicle inventory so you’ll need to
            configure the feed provider below and work directly with them to have this
            account’s inventory exported to BuyerBridge.
        </p>

        <v-form
            v-model="formValid"
            @submit.prevent="() => false">
            <styled-callout
                v-if="feedProvider && showSiteProviderDefaultMessage"
                class="mb-4 layout align-center">
                <div class="flex shrink mr-3">
                    <status-icon size="25" />
                </div>
                <div class="flex">
                    {{ siteProvider.name }} has been preselected as your feed provider
                    because you're using them as your site provider and we've found
                    their inventory to be more accurate for advertising use.
                </div>
            </styled-callout>
            <div class="layout">
                <div class="flex xs12 md6 mb-4">
                    <feed-provider-picker
                        :feed-provider-id="feedProviderId"
                        :feed-providers="feedProviders"
                        :loading="loadingFeedProviders"
                        :dealer-feed-rdi="dealerFeedRdi"
                        :feed-rdi-configured="feedRdiConfigured"
                        @input="setFeedProviderId"
                        @open-provider-dialog="newProviderDialogOpen = true" />
                </div>
            </div>
            <div
                v-if="feedProvider && !feedRdiConfigured"
                class="mb-5">
                <p>
                    Do you know the provider's ID for {{ currentDealer.name }}?
                </p>
                <feed-provider-id-picker
                    :feed-rdi-known="feedRdiKnown"
                    :feed-provider-name="feedProvider.name"
                    @input="setRdiKnown" />
                <div
                    v-if="feedRdiKnown == 'yes'"
                    class="mb-4">
                    <assign-id
                        :feed-provider="feedProvider"
                        :feed-rdi-type-id.sync="feedRdiTypeId"
                        :id-string-not-found-choice.sync="idStringNotFoundChoice"
                        @set-rdi-string="setRdiString"
                        @set-feed-rdi-string-status="setFeedRdiStringStatus"
                        @set-rdi-notification-emails="setRdiNotificationEmails"
                        @set-loading-state="setAssignIdLoading" />
                </div>

                <div
                    v-else-if="feedRdiKnown == 'no'"
                    class="mt-5">
                    <p class="mb-4">
                        Okay!  You’ll need follow these steps to have {{ feedProvider.name }}
                        export inventory for {{ currentDealer.name }} to BuyerBridge
                    </p>
                    <div class="layout wrap">
                        <div class="flex xs12 mb-4 md6">
                            <styled-list align-middle>
                                <div>
                                    Get written approval from an authorized contact at {{ currentDealer.name }}
                                    that authorizes the export of their inventory to BuyerBridge
                                    (<a
                                        href="#"
                                        @click.prevent="messagingDialogOpen = true">example</a>)
                                </div>
                                <div>
                                    Send a copy of the approval directly to {{ feedProvider.name }} and request
                                    that they export {{ currentDealer.name }} inventory to BuyerBridge
                                    (<a
                                        href="#"
                                        @click.prevent="messagingDialogOpen = true">example</a>)
                                </div>
                                <div>
                                    Once they’ve started the export {{ feedProvider.name }} will give you an ID
                                    for this account which you can enter in the next step.
                                </div>
                            </styled-list>
                        </div>
                        <div class="flex xs12 md5 offset-md1 mb-5">
                            <styled-button
                                class="mb-4"
                                outline
                                gray
                                full-width
                                @click="messagingDialogOpen = true">
                                VIEW &amp; SEND EMAILS
                            </styled-button>
                            <contact-information
                                v-if="feedProvider.support_phone || feedProvider.support_email"
                                :feed-provider="feedProvider" />
                        </div>
                    </div>
                    <p class="mb-5">
                        <a
                            href="https://support.buyerbridge.com/knowledge/managing-feed-providers"
                            target="_blank">
                            Need more help?  Click here for our help article on requesting feed data
                        </a>
                    </p>



                    <styled-callout
                        v-if="feedProvider.sync_elasticsearch && !dealerFeedRdi"
                        class="layout align-center mb-4">
                        <div class="flex shrink mr-3">
                            <status-icon
                                value="success"
                                size="25" />
                        </div>
                        <div class="flex">
                            <h3>ID Auto-Detection Supported for {{ feedProvider.name }}</h3>
                            <p>
                                BuyerBridge can automatically detect when {{ feedProvider.name }}
                                has sent us {{ currentDealer.name }}'s feed inventory.  Would
                                you like to enable this feature and have us email you once
                                we've processed the inventory?
                            </p>
                            <v-checkbox
                                v-model="useFeedAutoDetection"
                                color="primary"
                                class="styled-checkbox">
                                <template #label>
                                    <span class="mr-1">Yes, activate auto-detection and notify:</span>
                                    <notification-emails v-model="autoDetectNotificationEmails" />
                                </template>
                            </v-checkbox>
                        </div>
                    </styled-callout>
                </div>
            </div>
        </v-form>

        <div class="layout align-center">
            <back-button
                @click="$store.dispatch('goToPreviousStep')" />
            <styled-button
                class="ml-auto"
                :disabled="!valid"
                :loading="loadingRdiSetup"
                @click="onContinue">
                CONTINUE
            </styled-button>
        </div>
    </div>
</template>

<script>
import ResendFeedProviderDialog from '@/components/dealers/settings/panels/ResendFeedProvider';
import NotificationEmails from '@/components/globals/NotificationEmails';
import BackButton from '@/components/globals/BackButton';
import NewFeedProviderRequestSlideout from '@/components/globals/NewFeedProviderRequestSlideout';
import FeedProviderPicker from '@/components/globals/FeedProviderPicker';
import FeedProviderIdPicker from '@/components/globals/FeedProviderIdPicker';
import StyledList from '@/components/globals/StyledList';
import ContactInformation from '@/components/globals/InventoryRequestMessages/ContactInformation';
import StyledCallout from '@/components/globals/StyledCallout';
import StatusIcon from '@/components/globals/StatusIcon';
import StyledButton from '@/components/globals/StyledButton';
import AssignId from '@/components/globals/AssignId';
import { mapGetters, mapState } from 'vuex';
import { UPDATE_ONBOARDING_DATA } from '@/store/mutation-types';
import { requiredRules } from '@/helpers/validationRules';
import { WEBSITE_INVENTORY_SOURCES } from '@/helpers/globals';

export default {
    name: 'FeedInventoryOnboarding',
    components: {
        BackButton,
        ContactInformation,
        StyledList,
        StyledButton,
        AssignId,
        StyledCallout,
        StatusIcon,
        FeedProviderPicker,
        FeedProviderIdPicker,
        ResendFeedProviderDialog,
        NotificationEmails,
        NewFeedProviderRequestSlideout
    },
    data() {
        return {
            requiredRules,
            newProviderDialogOpen: false,
            messagingDialogOpen: false,
            feedProviders: [],
            feedProviderId: '',
            feedRdiString: '',
            feedRdiTypeId: 1,
            feedRdiStringStatus: null,
            loadingFeedProviders: null,
            loadingRdiSetup: false,
            error: null,
            siteProvider: null,
            showSiteProviderDefaultMessage: false,
            formValid: false,
            autoDetectNotificationEmails: [],
            rdiNotificationEmails: [],
            assignIdLoading: false,
        };
    },
    computed: {
        ...mapState({
            currentDealer: (state) => state.dealer.currentDealer,
            onboardingData: (state) => state.onboarding.data
        }),
        ...mapGetters([
            'dealerAddressString',
            'dealerHostname',
            'dealerFeedRdi'
        ]),
        feedProvider() {
            return this.feedProviders.find(feedProvider => {
                return feedProvider.id === this.feedProviderId;
            });
        },
        feedRdiConfigured() {
            if (!this.dealerFeedRdi) {
                return false;
            }

            return (this.dealerFeedRdi.id_string.length && this.dealerFeedRdi.id_string !== '-- temporary --');
        },
        valid() {
            if (this.assignIdLoading) {
                return false;
            }
            // If the RDI has already been configured the step is complete
            if (this.feedRdiConfigured) {
                return true;
            }

            // Consider the form valid if the user has defined a choice in
            // cases where the ID isn't found
            if (this.idStringNotFoundChoice) {
                return this.formValid;
            }

            // Don't consider the step valid until the RDI status has been retrieved
            // if the user has indicated that they know the RDI string
            if (this.feedRdiKnown === 'yes' && (this.feedRdiStringStatus !== 'found')) {
                return false;
            }

            return this.formValid;
        },
        useFeedAutoDetection: {
            get() {
                return this.onboardingData.useFeedAutoDetection || false;
            },
            set(value) {
                this.$store.commit(UPDATE_ONBOARDING_DATA, {
                    useFeedAutoDetection: value
                });
            }
        },
        idStringNotFoundChoice: {
            get() {
                return this.onboardingData.idStringNotFoundChoice || '';
            },
            set(choice) {
                this.$store.commit(UPDATE_ONBOARDING_DATA, {
                    idStringNotFoundChoice: choice
                });
            }
        },
        feedRdiKnown: {
            get() {
                return this.onboardingData.feedRdiKnown || null;
            },
            set(isKnown) {
                this.$store.commit(UPDATE_ONBOARDING_DATA, {
                    feedRdiKnown: isKnown
                });
            }
        },
        createdRdi: {
            get() {
                return this.onboardingData.createdRdi || null;
            },
            set(id) {
                this.$store.commit(UPDATE_ONBOARDING_DATA, {
                    createdRdi: id
                });
            }
        },
    },
    watch: {
        feedProvider() {
            if (this.feedProvider.default_rdi_id) {
                this.feedRdiTypeId = this.feedProvider.default_rdi_id;
            }

            if (this.showSiteProviderDefaultMessage && this.siteProvider.vehicle_data_source_id !== this.feedProviderId) {
                this.showSiteProviderDefaultMessage = false;
            }
        },
    },
    created() {
        this.init();
    },
    methods: {
        setRdiString(value) {
            this.feedRdiString = value;
        },
        setFeedRdiStringStatus(value) {
            this.feedRdiStringStatus = value;
        },
        setRdiNotificationEmails(value) {
            this.rdiNotificationEmails = value;
        },
        setAssignIdLoading(value) {
            this.assignIdLoading = value;
        },
        setFeedProviderId(id) {
            this.feedProviderId = id;
        },
        setRdiKnown(value) {
            this.feedRdiKnown = value;
        },
        async init() {
            if (this.dealerFeedRdi) {
                this.feedProviderId = this.dealerFeedRdi.vehicle_data_source_id;
            }
            // Otherwise get the site provider to potentially use it as the default
            else {
                this.getSiteProvider();
            }
            this.getFeedProviders();
        },
        async getSiteProvider() {
            try {
                const response = await this.$apiRepository.getSiteProvider(this.currentDealer.site_provider_id);
                this.siteProvider = response.data.data;
                if (this.siteProvider.vehicle_data_source_id) {
                    this.feedProviderId = this.siteProvider.vehicle_data_source_id;
                    this.showSiteProviderDefaultMessage = true;
                }
            } catch (error) {
                console.error();
                this.error = 'Error retrieving site provider';
            }
        },
        async getFeedProviders() {
            try {
                this.loadingFeedProviders = true;
                this.error = null;

                const response = await this.$apiRepository.getFeedProviders();
                const providers = response.data.data;
                // Filter our marketcheck
                this.feedProviders = providers.filter(provider => {
                    return !WEBSITE_INVENTORY_SOURCES.includes(provider.id);
                });
            } catch (error) {
                console.error('Error retrieving feed providers', error);
                this.error = 'Error retrieving feed providers';
            } finally {
                this.loadingFeedProviders = false;
            }
        },
        async onContinue() {

            // If the feed RDI is configured already there's nothing to do here
            if (this.feedRdiConfigured) {
                return this.$store.dispatch('goToNextStep');
            }

            this.loadingRdiSetup = true;

            // If there's no current Rdi create one
            if (!this.dealerFeedRdi) {

                await this.createRdi();

                // If the RDI string was found or the user chose to override
                // the validation try to process it
                if (this.feedRdiStringStatus === 'found' || this.idStringNotFoundChoice === 'process') {
                    await this.processRdi(this.createdRdi);
                }
            }
            // Otherwise if there's not a current ID string and the user has entered one
            // activate that RDI
            else if ((!this.dealerFeedRdi.id_string.length || this.dealerFeedRdi.id_string == '-- temporary --') && this.feedRdiString.length) {

                await this.activateRdi();

                // If the RDI string was found or the user chose to override
                // the validation try to process it
                if (this.feedRdiStringStatus === 'found' || this.idStringNotFoundChoice === 'process') {
                    await this.processRdi(this.dealerFeedRdi.id);
                }
            }

            this.loadingRdiSetup = false;

            this.$store.dispatch('goToNextStep');
        },
        async createRdi() {
            try {
                this.error = null;

                const data = {
                    feed_provider_id: this.feedProviderId,
                    rdi_type: (this.feedRdiKnown === 'yes') ? this.feedRdiTypeId : null,
                    id_string: (this.feedRdiKnown === 'yes')  ? this.feedRdiString : null,
                    auto_detect_id_string: this.useFeedAutoDetection,
                };

                if (this.feedRdiKnown === 'no' && this.useFeedAutoDetection) {
                    data.notification_email = this.autoDetectNotificationEmails;
                }
                else if (this.feedRdiKnown === 'yes' && this.idStringNotFoundChoice === 'notify') {
                    data.notification_email = this.rdiNotificationEmails;
                }
                const response = await this.$apiRepository.createRdi(this.currentDealer.id, data);
                this.createdRdi = response.data.data.id;

            } catch(error) {
                // eslint-disable-next-line no-console
                console.error('Error creating new feed:', error);
                this.error = 'There was an error while creating new feed.  Please try again or contact support if the problem continues.';
            }
        },
        async activateRdi() {
            try {
                const data = {
                    id_string: this.feedRdiString
                };

                if (this.idStringNotFoundChoice === 'notify') {
                    data.notification_email = this.rdiNotificationEmails;
                }
                await this.$apiRepository.activateRdi(this.currentDealer.id, this.dealerFeedRdi.id, data);
            } catch(error) {
                // eslint-disable-next-line no-console
                console.error('Error activating RDI string:', error);
                this.error = `There was an error while activating the RDI string: ${this.feedRdiString}.  Please try again or contact support if the problem continues.`;
            }
        },
        async processRdi(id) {
            try {
                const data = {
                    upload_exports: true,
                    notification_email: [],
                    upload_to_facebook: true,
                };
                await this.$http.post(`dealers/${this.currentDealer.id}/remote_dealer_identifiers/${id}/process-inventory`, data);
            } catch(error) {
                // eslint-disable-next-line no-console
                console.error('Error processing new feed:', error);
                this.error = 'There was an error trying to process the new feed.  Please try again or contact support if the problem continues.';
            }
        },
    }
};
</script>
